import React from "react"
import Layout from "../components/layout"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { graphql, Link } from "gatsby"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="bg-gradient-to-b  from-black/50  py-10 "></div>
      <section>
        <div className="container px-4 md:px-20 mx-auto  ">
          <Link to="/.">
            <div className="mt-20 flex ite gap-5">
              <ArrowLeftIcon className="w-5 mb-20" />

              <div className="">Back to home</div>
            </div>
          </Link>
          <div className=" font-made-bold text-4xl md:text-6xl mb-8 md:mb-14 ">
            Privacy Policy
          </div>
          <div className="mb-8">
            <div>
              {" "}
              Watawala Tea respects the privacy of its website visitors and is
              committed to protecting their personal information. This Privacy
              Policy outlines how we collect, use, and protect the information
              you provide when accessing and using our website,
              https://watawalatea.lk/. By using our website, you consent to the
              terms of this Privacy Policy.
            </div>
          </div>

          {/* <div className="">
            <div className="list-decimal my-8">
              <div>hello@moddsys.com</div>
              <div>+971 (0)4 852 5705</div>
              <div>Moddsys Technical Services LLC</div>
              <div>Plot number: 598-1096</div>
              <div>Street Number 51, Dubai Investment Park</div>
              <div>Dubai, UAE</div>
              <div>PO Box 1918</div>
              <div>TRN : 100359843800003</div>
            </div>
          </div> */}
          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Information Collection
          </div>
          <div className="mb-8">
            <div>
              {" "}
              Watawala Tea does not directly collect any personal data from
              website visitors. However, we have installed Facebook Pixel and
              Google Analytics on our website, which may collect anonymous
              information about user interactions for analytical purposes.
              Additionally, if you choose to submit comments or reviews through
              the forms provided on our website, we will collect and store the
              information you provide voluntarily.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Use of Personal Information
          </div>
          <div className="mb-8">
            <div>
              We do not collect personal information directly from website
              visitors, and therefore do not use it for any specific purposes.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Cookies and Tracking Technologies
          </div>
          <div className="mb-8">
            <div>
              We do not use cookies or other tracking technologies on our
              website.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Data Sharing
          </div>
          <div className="mb-8">
            <div>
              Watawala Tea does not share any personal data collected through
              the website with third parties.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Security Measures:
          </div>
          <div className="mb-8">
            <div>
              {" "}
              We take reasonable measures to protect the security of any
              information transmitted to us through our website. However, please
              note that no method of transmission over the internet or
              electronic storage is completely secure.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Updates to Privacy Policy
          </div>
          <div className="mb-8">
            <div>
              {" "}
              Any updates or changes to our Privacy Policy will be displayed on
              our website. It is your responsibility to review this policy
              periodically for any modifications.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Contact Information
          </div>
          <div className="mb-8">
            <div>
              {" "}
              If you have any questions or concerns regarding our Privacy Policy
              or the information we may have collected, you can contact us via
              email at watawala@sunshineholdings.lk or by phone at +94 011 4702
              400. Our address is No.60, Dharmapala Mawatha, Colombo 03, Sri
              Lanka.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Compliance with GDPR and CCPA
          </div>
          <div className="mb-8">
            <div>
              {" "}
              We are committed to complying with the General Data Protection
              Regulation (GDPR) and the California Consumer Privacy Act (CCPA),
              ensuring the protection and privacy of personal information as
              required by these regulations.
            </div>
          </div>
          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Rights of EEA Residents
          </div>
          <div className="mb-8">
            <div>
              {" "}
              This section is not applicable as we do not directly collect
              personal information from EEA residents.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            International Data Transfers
          </div>
          <div className="mb-8">
            <div> We do not transfer personal data outside Sri Lanka.</div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Your California Privacy Rights
          </div>
          <div className="mb-8">
            <div>
              {" "}
              If you are a California resident, you may have additional rights
              under the CCPA. Please refer to the "Your California Privacy
              Rights" section of our Privacy Policy for more information on how
              to exercise these rights.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Children's Privacy
          </div>
          <div className="mb-8">
            <div>
              {" "}
              IOur website is not intended for children under the age of 13, and
              we do not knowingly collect personal information from individuals
              in this age group.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Links to Third-Party Sites
          </div>
          <div className="mb-8">
            <div>
              {" "}
              Our Privacy Policy does not apply to any third-party websites that
              may be linked from our website. We encourage you to review the
              privacy policies of those websites before providing any personal
              information.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Security Policy
          </div>
          <div className="mb-8">
            <div>
              {" "}
              While we take precautions to protect your information, we cannot
              guarantee the security of any data transmitted over the internet.
              Please use caution when providing personal information online.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Your Rights and Obligations
          </div>
          <div className="mb-8">
            <div>
              {" "}
              Please keep your provided personal information up to date and
              ensure the confidentiality of your account and login credentials.
              You are responsible for all activities conducted under your
              account.
            </div>
          </div>

          <div className="mb-8 ">
            <div> Last Updated: June 23rd, 2023</div>
            <div className="mt-8 ">
              {" "}
              If you have any further questions or concerns about our Privacy
              Policy, please contact us at watawala@sunshineholdings.lk or by
              phone at +94 011 4702 400.
            </div>

            <div className="mt-8">
              By using our website, you acknowledge and agree to the terms of
              this Privacy Policy.
            </div>

            <div className="mt-8">
              Note: This revised privacy policy is effective immediately and
              applies to all information collected by Watawala Tea.
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy
